import React, { useState } from "react";
import "./index.scss";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ReactGA from "react-ga";
import Dashboards from "./components/Dashboards/Dashboards";
import Navigator from "./components/Navbar/Navbar";
import AboutUs from "./components/AboutUs/AboutUs";
import Devices from "./components/Devices/Devices";
import Join from "./components/Join/Join";
import Home from "./components/Home/Home";
import FAQ from "./components/FAQ/FAQ";
import GeneralPrivacy from "./components/TermsAndConditions/General/Privacy/GeneralPrivacy";
import GeneralSafety from "./components/TermsAndConditions/General/Safety/GeneralSafety";
import GeneralUsage from "./components/TermsAndConditions/General/Usage/GeneralUsage";
import LiteCampusPrivacy from "./components/TermsAndConditions/LiteCampus/Privacy/LiteCampusPrivacy";
import LiteCampusSafety from "./components/TermsAndConditions/LiteCampus/Safety/LiteCampusSafety";
import LiteCampusUsage from "./components/TermsAndConditions/LiteCampus/Usage/LiteCampusUsage";
import WhatsAppFloatingIcon from "./components/WhatsAppFloatingIcon/WhatsAppFloatingIcon";

const App = () => {
  const [mainPath] = useState(window.location.pathname.split("/")[1]);
  const [section] = useState(window.location.pathname.split("/")[2]);

  ReactGA.initialize("UA-145257254-2");

  if (section) {
    if (mainPath === "dashboards") {
      window.history.replaceState(null, null, "/dashboards");
    } else if (mainPath === "faq") {
      window.history.replaceState(null, null, "/faq");
    }
  } else {
    if (mainPath === "") {
      ReactGA.pageview(window.location.pathname + "home");
    } else {
      ReactGA.pageview(window.location.pathname);
      if (mainPath === "pmcg") {
        window.history.replaceState(null, null, "/dashboards");
      }
    }
  }

  return (
    <div>
      <Navigator />
      <Router>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/dashboards">
            <Dashboards section={section} analytics={ReactGA} />
          </Route>
          <Route path="/devices">
            <Devices />
          </Route>
          <Route path="/about">
            <AboutUs />
          </Route>
          <Route path="/join">
            <Join />
          </Route>
          <Route path="/faq">
            <FAQ section={section} analytics={ReactGA} />
          </Route>
          <Route path="/privacidade">
            <GeneralPrivacy />
          </Route>
          <Route path="/seguranca">
            <GeneralSafety />
          </Route>
          <Route path="/uso">
            <GeneralUsage />
          </Route>
          <Route path="/pmcg">
            <Dashboards section={"cities"} analytics={ReactGA} />
          </Route>
          <Route path="/litecampus/privacidade">
            <LiteCampusPrivacy />
          </Route>
          <Route path="/litecampus/seguranca">
            <LiteCampusSafety />
          </Route>
          <Route path="/litecampus/uso">
            <LiteCampusUsage />
          </Route>
          <Route path="/hw/litetagwifi1">
            {() => {
              window.location.href =
                "https://drive.google.com/file/d/1e3_GZGA0BQobYDp5pljStNBXoC766_1e/view?usp=share_link";
            }}
          </Route>
        </Switch>
      </Router>
      <WhatsAppFloatingIcon />
    </div>
  );
};

export default App;
