import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import "./WhatsAppFloatingIcon.scss";

const WhatsAppFloatingIcon = () => {
  return (
    <div className="whatsapp-floating-icon">
      <a
        href="https://api.whatsapp.com/send?phone=5583998611159"
        target="_blank"
        rel="noopener noreferrer"
        title="Fale conosco pelo WhatsApp"
      >
        <FontAwesomeIcon
          className="contact-icon"
          icon={faWhatsapp}
          size="2x"
          color="white"
        />
      </a>
    </div>
  );
};

export default WhatsAppFloatingIcon;
